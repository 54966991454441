import(/* webpackMode: "eager" */ "__barrel_optimize__?names=Breadcrumb!=!/builds/vrt/vrt/frontend/node_modules/antd/es/index.js");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/assets/images/social_share.png");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/assets/images/vrt-logo.png");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/atoms/BannerButton.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/atoms/DrupalImage.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/atoms/ListButton.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/molecules/NewsOverviewListWithoutFilters.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/molecules/TeaserContent.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/molecules/TocList.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/organisms/ArticleSlider.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/organisms/MaxSlider.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/organisms/NwsSlider.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/organisms/PodcastSlider.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/organisms/RelatedSlider.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/organisms/ShareButtons.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/organisms/SporzaSlider.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/paragraphs/AccordeonParagraph.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/paragraphs/VideoParagraph.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/tracking/BannerVisibilityTracker.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/tracking/PageImpressionTracking.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/tracking/VerticalListVisibilityTracker.tsx")